import React, { useEffect } from 'react'

import * as Styles from './LegalMenu.styled'

import Logo from '../Logo'
 import { ILogo } from '../types'

interface IAsideMenuProps {
  logo: ILogo
}

const AsideMenu: React.FC<IAsideMenuProps> = ({logo }) => {
  return <Styles.Root>
    <Styles.Logo>
      <Logo { ...logo } />
    </Styles.Logo>
  </Styles.Root>
}

export default AsideMenu
