import styled from "styled-components";
export const Wrapper = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200px;
    background: linear-gradient(180deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 0;
  }
`;

export const Header = styled.div`
  margin-bottom: 25px;
    @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
      margin-bottom: 40px;
    }
  }
`;

export const Slogan = styled.div`
  font-size: 26px;
  font-weight: 500;
  line-height: normal;
  color: ${(props) => props.theme.colors.text.contrast};
`;

export const Content = styled.div`
  margin-bottom: 60px;
z-index:1;
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
  }
`;

export const P = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: ${(props) => props.theme.colors.text.contrast};1
`;

export const StyledText = styled.span`
  color: ${(props) => props.theme.colors.thirty.default};
`;

export const StyledButton = styled.button`
  display: inline;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: inherit;
  font-family: inherit;
  color: ${(props) => props.theme.colors.thirty.default};
  text-decoration: none;
  cursor: pointer;

  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    font-size: 16px;
    line-height: 20px;
  }

  &:hover {
    text-decoration: none;
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`;
