import styled from "styled-components";
import { Link } from "react-router-dom";

import OpacityCardLibrary from "../../OpacityCard";

import ContactImageJPEG from "../../../images/rk/contact-image.svg";
import ContactImageMobileX3JPEG from "../../../images/rk/mobile-map-x3.svg";
import ContactImageMobileX4JPEG from "../../../images/rk/mobile-map-x4.svg";

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.main.darken};
  background-image: url(${ContactImageMobileX3JPEG});
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;

  @media ${(props) => props.theme.device.tablet} {
    background-image: url(${ContactImageMobileX4JPEG});
  }

  @media ${(props) => props.theme.device["tablet-landscape"]} {
    min-height: 100vh;
    background-image: url(${ContactImageJPEG});
    background-position: top center;
    background-size: cover;
  }

  @media ${(props) => props.theme.device["desktop"]} {
    min-height: 100vh;
    background-image: url(${ContactImageJPEG});
    background-position: top left;
    background-size: cover;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.div`
  margin-bottom: 160px;
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    margin-bottom: 90px;
  }
`;

export const Content = styled.div`
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    display: flex;
  }
`;

export const Description = styled.div`
  display: none;
  @media ${(props) => props.theme.device["desktop"]} {
    display: block;
    width: 500px;
    flex-shrink: 0;
    margin-right: 30px;
  }

  @media ${(props) => props.theme.device["desktop-medium"]} {
    display: block;
    width: 670px;
    flex-shrink: 0;
    margin-right: 30px;
  }
`;

export const Slogan = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: 500;
  @media ${(props) => props.theme.device["desktop"]} {
    font-size: 45px;
    line-height: 60px;
  }

  @media ${(props) => props.theme.device["desktop-medium"]} {
    font-size: 56px;
    line-height: 70px;
  }

  text-shadow: 0 5px 14px ${(props) => props.theme.colors.main.darken};
  color: ${(props) => props.theme.colors.text.contrast};
`;

export const Form = styled.div`
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    flex-grow: 1;
  }
`;

export const Social = styled.div`
  position: relative;
  padding-bottom: 15px;
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    padding-bottom: 25px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -15px;
    right: -15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    @media ${(props) => props.theme.device["desktop"]},
      @media ${(props) => props.theme.device["desktop-medium"]} {
      left: -20px;
      right: -20px;
    }
  }
`;

export const Feedback = styled.div`
  padding-top: 15px;
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    padding-top: 25px;
  }
`;

export const OpacityCard = styled(OpacityCardLibrary)`
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    padding: 25px 20px;
  }
`;
export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.legal.link};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  align-self: center;
`;

export const Rights = styled.div`
  color: ${(props) => props.theme.colors.legal.link};
  align-self: center;
`;

export const Footer = styled.div`
  padding-left: 110px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  gap: 11px;
  justify-content: flex-end;

  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: ${(props) => props.theme.colors.legal.text};
`;
