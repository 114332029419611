import React from 'react'

import * as Styles from './Contact.styled'

import Section from '../../common/Section'
import Slogan from '../SloganSection'
import OpacityCard from '../../OpacityCard'
import Contacts from '../../common/Contacts'

import { contacts } from '../../../data/vp'

const Contact = () => {

  return (
    <Styles.Wrapper>
      <Section>
      
        <Styles.Header>
          <Slogan>Контакты</Slogan>
        </Styles.Header>

        <Styles.Content>
          <Styles.Contact>
            <OpacityCard>
              <Contacts contacts={contacts} />
            </OpacityCard>
          </Styles.Contact>
        </Styles.Content>
      </Section>
      <Styles.Footer>
        <Styles.StyledLink to="/legal">Правовая информация</Styles.StyledLink>
        <Styles.Rights>{new Date().getFullYear()}г. ООО “Бидфокс”. Все права защищены.</Styles.Rights>
      </Styles.Footer>
    </Styles.Wrapper>
  );
}

export default Contact
