import styled from "styled-components";
import { container } from "../../styled/mixins";
import { Link } from "react-router-dom";

export const Root = styled.div`
z-index:1;
position: relative;
`;

export const Wrapper = styled.div`
  position: relative;
  background: radial-gradient(26.63% 49.16% at 98.16% 94.93%, rgba(255, 227, 154, 0.21) 0%, rgba(249, 202, 82, 0) 100%), radial-gradient(38.44% 59.09% at 76.15% 55.13%, rgba(163, 92, 220, 0.45) 0%, rgba(163, 92, 220, 0) 100%), radial-gradient(24.44% 62.58% at 38.82% 66.91%, rgba(22, 53, 215, 0.3) 0%, rgba(22, 53, 215, 0) 100%), radial-gradient(50.64% 67.16% at 63.02% 20.2%, rgba(163, 146, 243, 0.2) 0%, rgba(147, 129, 220, 0) 100%), radial-gradient(57.2% 67.63% at 33.13% 18.68%, rgba(72, 253, 253, 0.45) 0%, rgba(72, 242, 253, 0) 100%), #171133;
  
    @media ${props => props.theme.maxDevice['desktop']},
    @media ${props => props.theme.maxDevice['desktop-medium']} {
    padding-top: 55px;
  }
  
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 350px;
    //background: linear-gradient(180deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 0;
  }
`
export const Header = styled.div`
padding-top:35px;
padding-left: 110px;
`;
export const Container = styled.div`
  @media ${(props) => props.theme.maxDevice["desktop"]},
    @media ${(props) => props.theme.maxDevice["desktop-medium"]} {
    padding-left: 0;
    padding-right: 0;
  }
  ${container};
`;
export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.text.contrast};
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  &:hover{
    color: ${(props) => props.theme.colors.thirty.default};
    svg{
      path{stroke:${(props) => props.theme.colors.thirty.default};}
    }
  }
  svg{
    margin-right:10px;
  }
`;

export const Main = styled.main``;

export const Item = styled.div`
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    &:first-child section {
      padding-top: 35px;
    }
  }
`;
