import React from "react";

import "./styled/scss/fonts.scss";

import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";

import { getThemeApp, ThemeModes } from "./styled/theme";
import { GlobalStyle } from "./styled/globalStyle";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import VPPage from "./pages/VPPage";
import RKPage from "./pages/RKPage";
import VPLegalPage from "./pages/VPLegalPage";
import RKLegalPage from "./pages/RKLegalPage";

import "react-toastify/dist/ReactToastify.min.css";

function App() {
  const themeApp = getThemeApp(ThemeModes.Default);

  return (
    <ThemeProvider theme={themeApp}>
      <GlobalStyle />
      <Router>
        <div className="wrapper">
          <Switch>
            <Route path="/legal" component={process.env.REACT_APP_PERSONAL_AREA === "owner" ? VPLegalPage : RKLegalPage} />

            <Route path='*'
              component={
                process.env.REACT_APP_PERSONAL_AREA === 'owner'
                  ? VPPage
                  : RKPage
              }
             />
          </Switch>
        </div>
      </Router>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
