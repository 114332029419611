export type TSizes = {
  phone: number;
  tablet: number;
  'tablet-landscape': number;
  desktop: number;
  'desktop-medium': number;
  'desktop-large': number;
};

type TToSizes = {
  tablet: number;
  'tablet-landscape': number;
  desktop: number;
  'desktop-medium': number;
  'desktop-large': number;
};

const sizes: TSizes = {
  phone: 320,
  tablet: 600,
  'tablet-landscape': 900,
  desktop: 1200,
  'desktop-medium': 1355,
  'desktop-large': 1600,
};

const toSizes: TToSizes = {
  tablet: 599,
  'tablet-landscape': 899,
  desktop: 1199,
  'desktop-medium': 1354,
  'desktop-large': 1599,
};

export type TDevice = Record<keyof TSizes, string>;
const keysSizes = Object.keys(sizes) as (keyof TSizes)[];

export const device = keysSizes.reduce<TDevice>((acc, size) => {
  acc[size] = `(min-width: ${sizes[size]}px)`;
  return acc;
}, {} as TDevice);

export type TMaxDevice = Record<keyof TToSizes, string>;
const keysToSizes = Object.keys(toSizes) as (keyof TToSizes)[];

export const maxDevice = keysToSizes.reduce<TMaxDevice>((acc, size) => {
  acc[size] = `(max-width: ${toSizes[size]}px)`;
  return acc;
}, {} as TMaxDevice);

export type TFonts = {
  main: string;
  secondary: string;
};

export const fonts: TFonts = {
  main: '"Cera-Pro", sans-serif',
  secondary: 'Secondary',
};

export type TColors = {
  main: {
    darken: string;
    dark: string;
    default: string;
    light: string;
  };
  secondary: {
    darkness: string;
    dark: string;
    default: string;
    light: string;
    lighten: string;
    lightness: string;
  };
  thirty: {
    lighten: string;
    light: string;
    default: string;
  };
  gray: {
    darkness: string;
    darken: string;
    dark: string;
    default: string;
    light: string;
    lighten: string;
    lightness: string;
  };
  legal:{
    link: string;
    text: string;
  }
  text: {
    default: string;
    contrast: string;
  };
  indicator: {
    success: {
      dark: string;
      default: string;
      light: string;
      lighten: string;
    };
    error: {
      default: string;
      light: string;
    };
    info: {
      dark: string;
      default: string;
      light: string;
    };
    warning: {
      dark: string;
      default: string;
      light: string;
    };
  };
  tooltip: {
    default: string;
  };
};

type TColorsThemeApp = {
  default: TColors;
};

export const colors: TColorsThemeApp = {
  default: {
    main: {
      darken: '#171133',
      dark: '#7510D9',
      default: '#9338EE',
      light: '#AC58FF',
    },
    secondary: {
      darkness: '#13304C',
      dark: '#3A85CE',
      default: '#40A1FF',
      light: '#66B4FF',
      lighten: '#83A5C7',
      lightness: '#D5E1ED',
    },
    thirty: {
      lighten: '#C8FEF7',
      light: '#91FEEF',
      default: '#48FDE5',
    },
    gray: {
      darkness: '#424646',
      darken: '#5D5E5D',
      dark: '#939393',
      default: '#B7B9B8',
      light: '#D5D5D5',
      lighten: '#EAEAEA',
      lightness: '#F8F8F8',
    },
    legal:{
      link: 'rgba(255, 255, 255, 0.40)',
      text: 'rgba(255, 255, 255, 0.30)',
    },
    text: {
      default: 'rgba(255, 255, 255, 0.6)',
      contrast: '#FFFFFF',
    },
    indicator: {
      success: {
        dark: '#7ABD24',
        default: '#99D260',
        light: '#DCEFC9',
        lighten: '#F6F9F2',
      },
      error: {
        default: '#FF4062',
        light: '#FFF5F7',
      },
      info: {
        dark: '#308BDF',
        default: '#61B3FF',
        light: '#BEE0FF',
      },
      warning: {
        dark: '#EF9E00',
        default: '#FDD608',
        light: '#FFF5C1',
      },
    },
    tooltip: {
      default: 'rgba(53, 60, 59, 0.85)',
    },
  },
};
